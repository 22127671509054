.App {
  text-align: center;
}

body {
  /* background-color: #F7F0E4; */
  background-color: #ffffff;
  height: '100%';
  border: "3px solid green"
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#brand {
  color: white;
}

.page {
  padding: 20px;
  vertical-align: auto;
}

* {
  box-sizing: content-box;
}

[data-amplify-footer] {
  display: none;
}

[data-amplify-authenticator]
[data-amplify-router]{
  font-family: Mecherle-Sans;
  /* border-radius: 10px; */
  /* background: linear-gradient(140.01deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.5) 100%, rgba(255,255,255,0.4) 100%, rgba(255,255,255,0.4) 100%, rgba(255,255,255,0.4) 100%) ; */
  background: none;
  box-shadow: none;
  border: 0px solid black;
  margin-top: 15%;
  width: 100%;
}

[data-amplify-router] {
  /* width: 70%; */
  margin: auto;
  padding: 2%; 
}

[data-amplify-router-content] {
  width: 100%;
  margin: auto;
}

.amplify-button--primary {
  /* background-color: #D62311; */
  background-color: #282c34;
}

.amplify-button--primary:hover {
  /* background-color: #D62311; */
  background-color: #282c34;
  opacity: 0.6;
}

input:focus {
  border-color: #282c34;
}

#rect:hover {
  fill: #1161d6!important;
  fill-opacity: 0.4;
}

.MuiSlider-thumb{
  color: #D62311;
}
.MuiSlider-rail{
  color: #D62311;
}
.MuiSlider-track{
  color: #D62311;
}

.MuiAlert-message{
  font-size: 20px;
  font-family: 'Mecherle-Sans';
}

/* .info-button {
  color: #2F5F8C;
} */